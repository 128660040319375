import { AccessAttributeValueWithAttribute } from 'src/api/deinverband-api';
import { defaultRolesFilter } from 'src/hooks/api/use-targeting-editor';
import { CategoryOrId } from 'src/types/company';
import { Signature } from 'src/types/email';
import { Attendance } from 'src/types/occasion';
import {
  TargetingEditorState,
  TargetingListFilter,
  TargetingListFilterRights,
  TargetingListFilterRoles,
  TargetingListMember
} from 'src/types/targeting';
import { Capability, GroupRole } from 'src/types/user';
import { AnyAttributeValue, AttributeName, AttributeTypes } from './attributes';

// check if the attribute is of given type
const of = (value: AnyAttributeValue, name: AttributeName) =>
  ('attribute' in value && value.attribute?.name === name) ||
  // (attribute.attributeName === undefined || attribute.attributeName === name) &&
  value.name === name;

// check if the attributes are of given type
const only = (values: AnyAttributeValue[], names: AttributeName[]) =>
  values.every((value) => names.some((name) => of(value, name)));

// return unique values as list
const unique = <T>(values: T[]) => [...new Set(values)];

export const extractAttributeValues = <T extends keyof AttributeTypes>(
  name: T,
  values: AnyAttributeValue[]
): AttributeTypes[T][] =>
  values
    // attribute values without populated attribute are not filtered
    .filter((value) => of(value, name))
    .map((value) => JSON.parse(value.value) as AttributeTypes[T]);

export const extractUserGroupRoles = (
  values: AnyAttributeValue[]
): GroupRole[] =>
  extractAttributeValues('GroupRoles', values)
    .filter((value) => value.groupId !== null && value.roleId !== null)
    .map((value) => ({
      group: { id: value.groupId as number },
      role: { id: value.roleId as number }
    }));

export const extractUserCategories = (
  companyId: number,
  values: AnyAttributeValue[]
): CategoryOrId[] =>
  extractAttributeValues('CompanyCategoryAccess', values)
    .filter(
      (value) => value.companyId === companyId && value.categoryId !== null
    )
    .map((value) => ({ id: value.categoryId as number }));

export const extractTenantAdmin = (
  values: AccessAttributeValueWithAttribute[]
): boolean => values.some((value) => value.attribute.name === 'Admin');

export const extractEditor = (
  editorId: number | undefined,
  values: AnyAttributeValue[]
): boolean =>
  extractAttributeValues('Editor', values).some(
    (value) => value.editorId === editorId
  );

export const extractCapabilities = (
  values: AnyAttributeValue[]
): Capability[] =>
  extractAttributeValues('Capabilities', values).flatMap(
    (value) => value.capabilities as Capability[]
  );

export const extractGroupsMatrix = <T extends { groupId: number | null }>(
  values: T[],
  source: keyof T
) => {
  // find distinct groups
  const uniqueGroups = unique(values.map((value) => value.groupId));
  // list items per group
  const groupsWithItems = uniqueGroups.map((group) => ({
    group,
    items: unique(
      values
        .filter((value) => value.groupId === group)
        .map((value) => value[source])
    ).sort()
  }));

  // find distinct sets of items
  const uniqueSetsOfItems = new Map(
    groupsWithItems.map((value) => [
      // simple hash to identify distinct sets
      value.items.join('.'),
      value.items
    ])
  );

  // list groups per set of items
  return [...uniqueSetsOfItems].map(([hash, items]) => ({
    groups: groupsWithItems
      // find all groups with exactly this set of items
      .filter((value) => value.items.join('.') === hash)
      .map(({ group }) => ({ id: group })),
    items: items.map((item) => ({ id: item as number | null }))
  }));
};

export const extractTargetingFilterRights = (
  values: AnyAttributeValue[]
): TargetingListFilterRights[] => {
  const attributes = [
    ...extractAttributeValues('CompanyCategoryGroupMembership', values),
    ...extractAttributeValues('CompanyGroupCategory', values).map((value) => ({
      ...value,
      // attributes without membership fall back to all memberships
      membershipId: null
    }))
  ];

  // find distinct memberships
  const memberships = unique(attributes.map((value) => value.membershipId));
  // list matrices per membership
  return memberships.flatMap((membershipId) =>
    extractGroupsMatrix(
      attributes.filter((value) => value.membershipId === membershipId),
      'categoryId'
    ).map(
      // TODO: group matrix
      ({ groups, items }): TargetingListFilterRights => ({
        type: 'rights',
        memberships: [{ id: membershipId }],
        groups,
        roles: items
      })
    )
  );
};

export const extractTargetingFilterRoles = (
  values: AnyAttributeValue[]
): TargetingListFilterRoles[] => {
  const attributes = [
    // NOTE: membership is currently not supported
    ...extractAttributeValues('GroupRolesMembership', values),
    ...extractAttributeValues('GroupRoles', values)
  ];

  return extractGroupsMatrix(attributes, 'roleId').map(
    // TODO: group matrix
    ({ groups, items }): TargetingListFilterRoles => ({
      type: 'roles',
      groups,
      roles: items
    })
  );
};

export const extractTargetingFilters = (
  values: AnyAttributeValue[]
): TargetingListFilter[] => {
  return [
    ...extractTargetingFilterRights(values),
    ...extractTargetingFilterRoles(values)
  ];
};

export const extractTargetingLocations = (
  attributeName:
    | 'GroupRolesMembership'
    | 'GroupRoles'
    | 'CompanyCategoryGroupMembership'
    | 'CompanyGroupCategory',
  values: AnyAttributeValue[]
): string[] =>
  unique(
    extractAttributeValues(attributeName, values)
      .map((value) => value.location)
      // null means no location filter
      .filter((value) => value !== null)
  );

export const extractTargetingMembers = (
  values: AnyAttributeValue[]
): TargetingListMember[] | null => {
  const readers = unique(
    extractAttributeValues('CustomAccess', values).map(({ userId }) => userId)
  );
  const viewers = unique(
    extractAttributeValues('Viewer', values).map(({ viewerId }) => viewerId)
  );
  const editors = unique(
    extractAttributeValues('Editor', values).map(({ editorId }) => editorId)
  );
  const creators = unique(
    extractAttributeValues('Creator', values).map(({ creatorId }) => creatorId)
  );

  if (readers.length === 0 && viewers.length === 0 && editors.length === 0)
    // no additional members
    return null;

  // creators should be excluded from other roles
  return [
    ...readers
      .filter((id) => !creators.includes(id))
      .map((id): TargetingListMember => ({ member: { id }, role: 'reader' })),
    ...viewers
      .filter((id) => !creators.includes(id))
      .map((id): TargetingListMember => ({ member: { id }, role: 'viewer' })),
    ...editors
      .filter((id) => !creators.includes(id))
      .map((id): TargetingListMember => ({ member: { id }, role: 'editor' }))
  ];
};

export const extractTargetingAttendances = (
  values: AnyAttributeValue[]
): Attendance[] =>
  unique(
    extractAttributeValues('AttendeeStatus', values).flatMap(
      (value) => value.statuses as Attendance[]
    )
  );

export const extractTargetingAttendanceEventId = (
  values: AnyAttributeValue[]
): number | undefined => {
  const eventIds = unique(
    extractAttributeValues('AttendeeStatus', values).map(
      (value) => value.eventId
    )
  );

  return eventIds.length === 1 ? eventIds[0] : undefined;
};

export const extractTargetingEditorState = (
  signature: Signature | undefined,
  values: AnyAttributeValue[]
): TargetingEditorState | undefined => {
  // The targeting editor state is recognized by the attributes
  // being of a specific type. Some tags can be ignored.
  const allowed = ['Creator', 'Editor'] as const;

  if (
    only(values, [
      'GroupRolesMembership',
      'GroupRoles',
      'CompanyCategoryGroupMembership',
      'CompanyGroupCategory',
      'Viewer',
      'CustomAccess',
      ...allowed
    ])
  ) {
    const filters = extractTargetingFilters(values);
    const locations = unique([
      ...extractTargetingLocations('GroupRolesMembership', values),
      ...extractTargetingLocations('GroupRoles', values),
      ...extractTargetingLocations('CompanyCategoryGroupMembership', values),
      ...extractTargetingLocations('CompanyGroupCategory', values)
    ]);
    const members = extractTargetingMembers(values);
    return {
      method: 'filters',
      filters: filters.length ? filters : [defaultRolesFilter],
      locations: locations.length ? locations : null,
      members: members?.length ? members : null,
      signature
    };
  }

  if (only(values, ['AttendeeStatus', ...allowed])) {
    const eventId = extractTargetingAttendanceEventId(values);
    if (eventId === undefined) {
      console.error(`Invalid targeting state: missing eventId`);
      return undefined;
    }
    return {
      method: 'attendees',
      attendances: extractTargetingAttendances(values),
      eventId
    };
  }

  console.error('Invalid targeting state');
  // reset targeting state on post
  return undefined;
};
