import { emptyPreflightApi as api } from './empty-api';
export const addTagTypes = ['Event Resource', 'Post Resource'] as const;
const injectedRtkApi = api
  .enhanceEndpoints({
    addTagTypes
  })
  .injectEndpoints({
    endpoints: (build) => ({
      getApiEventsByIdAttendees: build.query<
        GetApiEventsByIdAttendeesApiResponse,
        GetApiEventsByIdAttendeesApiArg
      >({
        query: (queryArg) => ({
          url: `/api/events/${queryArg.id}/attendees`,
          params: {
            page: queryArg.page,
            search: queryArg.search,
            size: queryArg.size,
            sortKey: queryArg.sortKey,
            sortOrder: queryArg.sortOrder
          }
        }),
        providesTags: ['Event Resource']
      }),
      deleteApiPostsById: build.query<
        DeleteApiPostsByIdApiResponse,
        DeleteApiPostsByIdApiArg
      >({
        query: (queryArg) => ({
          url: `/api/posts/${queryArg.id}`,
          method: 'DELETE'
        }),
        providesTags: ['Post Resource']
      }),
      patchApiPostsById: build.query<
        PatchApiPostsByIdApiResponse,
        PatchApiPostsByIdApiArg
      >({
        query: (queryArg) => ({
          url: `/api/posts/${queryArg.id}`,
          method: 'PATCH',
          body: queryArg.updatePost
        }),
        providesTags: ['Post Resource']
      }),
      getApiPostsByIdRecipients: build.query<
        GetApiPostsByIdRecipientsApiResponse,
        GetApiPostsByIdRecipientsApiArg
      >({
        query: (queryArg) => ({
          url: `/api/posts/${queryArg.id}/recipients`,
          params: {
            emailFilter: queryArg.emailFilter,
            page: queryArg.page,
            search: queryArg.search,
            size: queryArg.size,
            sortKey: queryArg.sortKey,
            sortOrder: queryArg.sortOrder
          }
        }),
        providesTags: ['Post Resource']
      })
    }),
    overrideExisting: false
  });
export { injectedRtkApi as preflightApi };
export type GetApiEventsByIdAttendeesApiResponse =
  /** status 200 OK */ PaginatedResponseAttendResponseRead;
export type GetApiEventsByIdAttendeesApiArg = {
  id: number;
  page?: number | null;
  search?: string | null;
  size?: number | null;
  sortKey: UserSortKey & any;
  sortOrder: SortOrder & any;
};
export type DeleteApiPostsByIdApiResponse = unknown;
export type DeleteApiPostsByIdApiArg = {
  id: number;
};
export type PatchApiPostsByIdApiResponse = unknown;
export type PatchApiPostsByIdApiArg = {
  id: number;
  updatePost: UpdatePost;
};
export type GetApiPostsByIdRecipientsApiResponse =
  /** status 200 OK */ PaginatedResponsePostRecipient;
export type GetApiPostsByIdRecipientsApiArg = {
  id: number;
  emailFilter: UserEmailFilter & any;
  page?: number | null;
  search?: string | null;
  size?: number | null;
  sortKey: EmailUserSortKey & any;
  sortOrder: SortOrder & any;
};
export type Gender = 'FEMALE' | 'MALE' | 'DIVERSE' | 'UNKNOWN';
export type EmailUser = {
  id?: number;
  firstName?: string | null;
  lastName?: string | null;
  gender?: Gender | null;
  email?: string | null;
  companyName?: string | null;
  companyLocation?: string | null;
  isExternal?: boolean | null;
  isCompanyUser?: boolean | null;
  external?: boolean | null;
  companyUser?: boolean | null;
  emailValid?: boolean;
};
export type AttendeeStatus =
  | 'ATTENDING'
  | 'NOT_ATTENDING'
  | 'MAYBE_ATTENDING'
  | 'NO_ANSWER';
export type Instant = string;
export type Survey = {
  title?: string | null;
  json: string;
  responseDeadline: Instant;
  createdAt: Instant;
  isTemplate?: boolean;
  description?: string | null;
};
export type SurveyRead = {
  id?: number;
  title?: string | null;
  json: string;
  responseDeadline: Instant;
  createdAt: Instant;
  creator?: EmailUser | null;
  isTemplate?: boolean;
  description?: string | null;
};
export type SurveyResponse = {
  user: EmailUser;
  json: string;
  createdAt: Instant;
  updatedAt: Instant;
  publishedAt?: Instant | null;
};
export type SurveyResponseRead = {
  id?: number;
  user: EmailUser;
  json: string;
  createdAt: Instant;
  updatedAt: Instant;
  publishedAt?: Instant | null;
};
export type SurveyWithResponse = {
  survey: Survey;
  response?: SurveyResponse | null;
  token: string;
};
export type SurveyWithResponseRead = {
  survey: SurveyRead;
  response?: SurveyResponseRead | null;
  token: string;
};
export type AttendResponse = {
  user: EmailUser;
  status: AttendeeStatus;
  surveyWithResponse?: SurveyWithResponse | null;
};
export type AttendResponseRead = {
  user: EmailUser;
  status: AttendeeStatus;
  surveyWithResponse?: SurveyWithResponseRead | null;
};
export type PaginationMetadata = {
  page?: number;
  size?: number;
  totalItems?: number;
  totalPages?: number;
  next?: string | null;
  previous?: string | null;
};
export type PaginatedResponseAttendResponse = {
  data: AttendResponse[];
  meta: PaginationMetadata;
};
export type PaginatedResponseAttendResponseRead = {
  data: AttendResponseRead[];
  meta: PaginationMetadata;
};
export type UserSortKey =
  | 'FIRST_NAME'
  | 'LAST_NAME'
  | 'EMAIL'
  | 'COMPANY'
  | 'GUILD'
  | 'ROLE';
export type SortOrder = 'ASC' | 'DESC';
export type CreateAttributeValueByName = {
  attributeName: string;
  value: string;
};
export type PostSection = {
  id: string;
  content: string;
};
export type UpdatePost = {
  title?: string | null;
  bodyText?: string | null;
  bodyHtml?: string | null;
  bodyQuill?: string | null;
  categoryId?: number | null;
  event?: {
    name?: string | null;
    description?: string | null;
    eventTypeId?: number | null;
    onlineMeetingUrl?: string | null;
    startAt?: string | null;
    endAt?: string | null;
    location?: {
      name?: string | null;
      streetAddress?: string | null;
      locality?: string | null;
      postalCode?: string | null;
      lat?: number | null;
      lng?: number | null;
      plusCode?: string | null;
    } | null;
    startAtDay?: string | null;
    endAtDay?: string | null;
    responseDeadline?: string | null;
    maxAttendees?: number | null;
  } | null;
  recipients?: {
    accessAttributeValueIds: number[];
    accessAttributes: CreateAttributeValueByName[];
  } | null;
  emailTemplateId?: number | null;
  imageUrl?: string | null;
  attachments?: number[] | null;
  emailSignatureId?: number | null;
  tagIds?: number[] | null;
  publishDelayedAt?: string | null;
  teaser?: string | null;
  surveyId?: number | null;
  feedText?: string | null;
  sections?: PostSection[] | null;
};
export type LocalDateTime = string;
export type PostNotificationError = {
  type: string;
  message: string;
  description?: string | null;
  createdAt: LocalDateTime;
  postNotificationId?: number | null;
};
export type PostNotification = {
  id?: number;
  userId?: number;
  type: string;
  queuedAt: LocalDateTime;
  sentAt?: LocalDateTime | null;
  receivedAt?: LocalDateTime | null;
  readAt?: LocalDateTime | null;
  errors: PostNotificationError[];
};
export type NotificationSettings = {
  tagId?: number | null;
  email?: boolean;
  cloudMessage?: boolean;
  fax?: boolean;
  postal?: boolean;
};
export type PostRecipient = {
  user: EmailUser;
  notifications: PostNotification[];
  notificationSettings?: NotificationSettings | null;
};
export type PaginatedResponsePostRecipient = {
  data: PostRecipient[];
  meta: PaginationMetadata;
};
export type UserEmailFilter = 'ALL' | 'HAS_EMAIL' | 'HAS_NO_EMAIL';
export type EmailUserSortKey =
  | 'FIRST_NAME'
  | 'LAST_NAME'
  | 'EMAIL'
  | 'COMPANY'
  | 'LOCATION';
export const {
  useGetApiEventsByIdAttendeesQuery,
  useDeleteApiPostsByIdQuery,
  usePatchApiPostsByIdQuery,
  useGetApiPostsByIdRecipientsQuery
} = injectedRtkApi;
