import { useEffect } from 'react';
import * as Sentry from '@sentry/react';
import { matchRoutes } from 'react-router';
import {
  createRoutesFromChildren,
  useLocation,
  useNavigationType
} from 'react-router-dom';
import versionInfo from '../utils/version';

export const feedbackIntegration = Sentry.feedbackIntegration({
  autoInject: false,
  showBranding: false,
  colorScheme: 'system',
  buttonLabel: 'Fehler melden',
  submitButtonLabel: 'Fehlermeldung absenden',
  cancelButtonLabel: 'Abbrechen',
  formTitle: 'Technischen Fehler an Verbandspartner melden',
  nameLabel: 'Name',
  namePlaceholder: 'Ihr Name',
  emailLabel: 'E-Mail',
  emailPlaceholder: 'ihre.email-adresse@beispiel.de',
  messageLabel: 'Beschreibung',
  messagePlaceholder:
    'Was haben Sie erwartet und was ist stattdessen passiert? Was führte dazu?',
  successMessageText:
    'Vielen Dank für Ihre Fehlermeldung. Wir werden uns darum kümmern.',
  isRequiredLabel: '(Pflichtfeld)',
  // HACK: Fix problem with MUI dialogs: In order to be able to focus the
  // Sentry input fields when a MUI dialog is open, the tabindex property
  // needs to be removed from the MUI dialog. It is restored after the
  // Sentry dialog is closed.
  // See: https://github.com/getsentry/sentry-javascript/issues/2941
  onFormOpen: () => {
    document.querySelectorAll('[tabindex="-1"]').forEach(function (el) {
      el.setAttribute('old-tabindex', '-1');
      el.removeAttribute('tabindex');
    });
  },
  onFormClose: () => {
    document.querySelectorAll('[old-tabindex="-1"]').forEach(function (el) {
      el.setAttribute('tabindex', '-1');
      el.removeAttribute('old-tabindex');
    });
  }
});

Sentry.init({
  dsn: 'https://fb8260cc7990f4df70a666fc6366e9e4@o4507073789362176.ingest.de.sentry.io/4507604737589328',
  integrations: [
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes
    }),
    // Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
    feedbackIntegration
  ],
  tracesSampleRate: 1.0,
  tracePropagationTargets: [/^\//, 'localhost'],
  replaysSessionSampleRate: 1,
  replaysOnErrorSampleRate: 1.0,
  environment: import.meta.env.MODE,
  beforeSend(event) {
    if (event.user) {
      // remove user's personal data
      delete event.user.email;
      delete event.user.username;
      delete event.user.ip_address;
    }
    return event;
  }
});

Sentry.setTag('appVersion', versionInfo.version);
Sentry.setTag('commitHash', versionInfo.commitHash);
Sentry.setTag('environment', import.meta.env.MODE);
