import { Capability } from 'src/types/user';
import { useMe } from './contexts/use-me';

export const useCapabilities = (...capabilities: Capability[]) => {
  const profile = useMe();

  return capabilities.map(
    (capability) =>
      profile.tenantAdmin || profile.capabilities.includes(capability)
  );
};
