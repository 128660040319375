import { CapacitorHttp, HttpResponseType } from '@capacitor/core';
import { BaseQueryFn } from '@reduxjs/toolkit/dist/query';
import * as firebaseAuth from 'firebase/auth';

// auto-inject firebase token
let firebaseCurrentUser: firebaseAuth.User | null = null;

export const defaultHeaders = {
  Accept: '*/*',
  'Content-Type': 'application/json'
};

export interface CapacitorBaseQueryOptions {
  baseUrl: string;
  enableFirebaseAuth?: boolean;
  abacPreflight?: boolean;
}

export interface CapacitorBaseQueryArgs {
  url: string;
  method?: string;
  body?: any;
  params?: { [key: string]: any };
  headers?: { [key: string]: string };
}

const injectFirebaseAuth = async (args: CapacitorBaseQueryArgs) => {
  // use the current (valid) token or refresh the token now
  // (this avoids manual token management)
  const token = await firebaseCurrentUser?.getIdToken();

  if (token)
    args.headers = {
      ...args.headers,
      Authorization: `Bearer ${token}`
    };
};

const injectAbacPreflight = (args: CapacitorBaseQueryArgs) => {
  args.headers = {
    ...args.headers,
    'X-ABAC-Preflight': 'true'
  };
};

const filterUndefined = (params: { [key: string]: any }) =>
  Object.fromEntries(
    Object.entries(params).filter(([key, value]) => value !== undefined)
  );

/**
 * RTK base query which uses capacitor HTTP to abstract fetch.
 *
 * This allows to use native request APIs on non-web platforms,
 * and therefore enables cross-origin requests.
 */
export const capacitorBaseQuery =
  ({
    baseUrl,
    enableFirebaseAuth,
    abacPreflight
  }: CapacitorBaseQueryOptions): BaseQueryFn<
    CapacitorBaseQueryArgs,
    unknown,
    unknown,
    { responseType?: HttpResponseType } | undefined
  > =>
  async (args, api, extraOptions) => {
    if (enableFirebaseAuth) await injectFirebaseAuth(args);
    if (abacPreflight) injectAbacPreflight(args);

    const { url, method, body, params, headers } = args;
    const result = await CapacitorHttp.request({
      url: baseUrl + url,
      method,
      data: body,
      params: params && filterUndefined(params),
      headers: { ...defaultHeaders, ...headers },
      responseType: extraOptions?.responseType
    });

    if (result.status >= 200 && result.status < 400)
      return { ...result, meta: result };

    return { error: result };
  };

export const updateFirebaseCurrentUser = (user: firebaseAuth.User | null) => {
  firebaseCurrentUser = user;
  // authenticated requests available
  console.info('[capacitor-adapter] Firebase user changed:', !!user);
};
