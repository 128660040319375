import { combineReducers } from '@reduxjs/toolkit';
import {
  emptyDeinVerbandApi,
  emptyOpenPlzApi,
  emptyPreflightApi
} from 'src/api/empty-api';
import { getStreamApi } from 'src/api/getstream-api';
import { tenantApi } from 'src/api/tenant-api';
import { reducer as commonReducer } from 'src/slices/common';

export const rootReducer = combineReducers({
  common: commonReducer,
  [emptyDeinVerbandApi.reducerPath]: emptyDeinVerbandApi.reducer,
  [emptyOpenPlzApi.reducerPath]: emptyOpenPlzApi.reducer,
  [emptyPreflightApi.reducerPath]: emptyPreflightApi.reducer,
  [tenantApi.reducerPath]: tenantApi.reducer,
  [getStreamApi.reducerPath]: getStreamApi.reducer
});
