import { FeatureFlag } from 'src/types/features';

/**
 * Global feature flags (initialized by auth guard)
 */
export let featureFlags: FeatureFlag[] | undefined = undefined;

export const updateFeatureFlags = (flags: FeatureFlag[]) => {
  featureFlags = flags;
};

/**
 * Check for multiple feature flags at once
 */
export const hasFeatureFlags = (...flags: FeatureFlag[]) => {
  return flags.map((flag) => featureFlags?.includes(flag));
};

/**
 * Check for single feature flag
 */
export const hasFeatureFlag = (flag: FeatureFlag) => {
  return featureFlags?.includes(flag);
};
