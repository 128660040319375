import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { appConfig } from 'src/config';
import { capacitorBaseQuery } from './capacitor-adapter';

export const emptyDeinVerbandApi = createApi({
  baseQuery: capacitorBaseQuery({
    baseUrl: appConfig.baseUrl || '',
    enableFirebaseAuth: true
  }),
  endpoints: () => ({}),
  reducerPath: 'deinVerbandApi'
});

export const emptyOpenPlzApi = createApi({
  baseQuery: fetchBaseQuery({
    baseUrl: (appConfig.baseUrl || '') + '/openplz'
  }),
  endpoints: () => ({}),
  reducerPath: 'openPlzApi'
});

export const emptyPreflightApi = createApi({
  baseQuery: capacitorBaseQuery({
    baseUrl: appConfig.baseUrl || '',
    enableFirebaseAuth: true,
    abacPreflight: true
  }),
  endpoints: () => ({}),
  reducerPath: 'preflightApi'
});
