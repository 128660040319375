import { registerSW } from 'virtual:pwa-register';

const refreshInterval = 1000 * 60 * 15;

// register the VitePWA service worker
// (including firebase-messaging-sw)
const registerServiceWorker = async () =>
  new Promise<ServiceWorkerRegistration>((resolve) => {
    registerSW({
      onRegisteredSW(script, registration) {
        console.log('[sw] Registered:', !!registration);
        if (registration) resolve(registration);
      },
      onNeedRefresh() {
        console.log('[sw] Refresh needed');
        // new version is loaded when user reloads page through browser
        // (to avoid data loss on reload)
        // window.location.reload();
      },
      onOfflineReady() {
        console.log('[sw] Offline ready');
      }
    });
  });

export const messageServiceWorker = async (type: string, payload: unknown) => {
  if (!swRegistration?.active) return console.warn('[sw] Not available');
  swRegistration.active.postMessage({ type, payload });
};

// provide access to service worker registration
export let swRegistration: ServiceWorkerRegistration | undefined = undefined;
export const swRegistrationPromise = registerServiceWorker();

swRegistrationPromise
  .then((registration) => {
    swRegistration = registration;

    // check for service worker updates
    swRegistration.update();
    setInterval(() => registration.update(), refreshInterval);
  })
  .catch(console.error);
