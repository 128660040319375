import {
  extractCapabilities,
  extractEditor,
  extractTenantAdmin,
  extractUserCategories,
  extractUserGroupRoles
} from 'src/abac/extract';
import { UserDetails } from 'src/api/deinverband-api';
import { Profile } from 'src/types/user';
import { getGender } from 'src/utils/get-gender';
import { mapCompanySummaryToCompany } from './company-summary-to-company';

export const mapUserDetailsToProfile = (
  user: UserDetails | undefined
): Profile | undefined => {
  if (!user || user.id === undefined) return undefined;

  const companyAccount = !!user.isCompanyUser;

  return {
    id: user.id,
    email: user.email || undefined,
    gender: getGender(user.gender),
    firstName: (!companyAccount && user.firstName) || undefined,
    lastName: (!companyAccount && user.lastName) || undefined,
    contact: {
      phone: user.phone || undefined,
      mobile: user.mobile || undefined
    },
    company: mapCompanySummaryToCompany(user.company || undefined),
    groupRoles: extractUserGroupRoles(user.appliedAccessAttributes),
    categoryAccess: user.company?.id
      ? extractUserCategories(user.company.id, user.appliedAccessAttributes)
      : [],
    external: !!user.isExternal,
    companyAccount: companyAccount,
    defaultNotifications: user.defaultNotificationSettings,
    tagNotifications: user.tagNotificationSettings,
    tenantAdmin: extractTenantAdmin(user.appliedAccessAttributes),
    editor: extractEditor(user.id, user.appliedAccessAttributes),
    capabilities: extractCapabilities(user.appliedAccessAttributes)
  };
};
