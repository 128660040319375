import type { FC, PropsWithChildren } from 'react';
import { createContext, useEffect, useMemo } from 'react';
import * as Sentry from '@sentry/browser';
import { enhancedApi } from 'src/api/enhanced-api';
import { useFirebase } from 'src/hooks/contexts/use-firebase';
import { useTenantContext } from 'src/hooks/contexts/use-tenant';
import { updateFeatureFlags } from 'src/libs/feature-flags';
import { mapUserDetailsToProfile } from 'src/mappings/user-details-to-profile';
import { Profile } from 'src/types/user';
import { getFullName } from 'src/utils/get-full-name';

export const ProfileContext = createContext<Profile | undefined>(undefined);

/**
 * Provide the user's profile information to children
 *
 * NOTE: This component is deployed inside the main app component. This allows
 * updates on logout/when not logged in (i.e. updating Sentry). Therefore, it
 * needs to render children, even when not authenticated (meaning the profile is
 * not available). Children should use the AuthGuard if authentication is
 * required.
 */
export const ProfileProvider: FC<PropsWithChildren> = (props) => {
  const { children } = props;

  const { authenticated, firebaseUser } = useFirebase();
  const { style } = useTenantContext();

  const query = enhancedApi.useGetApiUsersMeQuery(undefined, {
    skip: !authenticated
  });

  const profile = useMemo(
    () => {
      const profile = mapUserDetailsToProfile(query.data);

      // update global feature flags before the profile changes,
      // to ensure rendering is done with the correct flags
      updateFeatureFlags(
        profile?.tenantAdmin ? style.adminFeatures : style.userFeatures
      );

      return profile;
    },
    // feature flags will not change
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [query.data]
  );

  // update the Sentry user information whenever possible
  // (this might execute multiple times during login)
  useEffect(() => {
    if (authenticated)
      Sentry.setUser({
        id: firebaseUser?.uid,
        email: profile?.email,
        username: getFullName(profile)
      });
    else Sentry.setUser(null);
  }, [authenticated, profile, firebaseUser]);
  /*
  // provide a fallback for invalid profiles
  useEffect(() => {
    if (query.isError) signOut();
  }, [signOut, query.isError]);
  */
  return (
    <ProfileContext.Provider value={profile}>
      {children}
    </ProfileContext.Provider>
  );
};
