import { type FC } from 'react';
import { Icon } from '@iconify/react';
import { Button, Theme, useMediaQuery } from '@mui/material';
import { IconifyButton } from 'src/components/iconify-button';
import { feedbackIntegration } from 'src/libs/sentry';

export const SentryButton: FC = () => {
  const smUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('sm'));

  if (!smUp)
    return (
      <Button
        onClick={() => feedbackIntegration.openDialog()}
        sx={{
          alignItems: 'center',
          display: 'flex',
          borderWidth: 2,
          borderStyle: 'solid',
          borderColor: 'divider',
          height: 40,
          width: 40,
          minWidth: 40,
          borderRadius: 20,
          backgroundColor: 'black',
          color: 'white',

          '&:hover': {
            backgroundColor: '#333',
            color: 'white'
          }
        }}
      >
        <Icon icon="fa6-solid:bullhorn" />
      </Button>
    );

  return (
    <IconifyButton
      startIcon="fa6-solid:bullhorn"
      onClick={() => feedbackIntegration.openDialog()}
      sx={{
        alignItems: 'center',
        display: 'flex',
        borderWidth: 2,
        borderStyle: 'solid',
        borderColor: 'divider',
        height: 40,
        borderRadius: 20,
        backgroundColor: 'black',
        color: 'white',

        '&:hover': {
          backgroundColor: '#333',
          color: 'white'
        }
      }}
    >
      Fehler melden
    </IconifyButton>
  );
};
