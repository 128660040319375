import { ReactNode, useMemo } from 'react';
import { useGetUnreadQuery } from 'src/api/getstream-api';
import { InlineIcon } from 'src/components/inline-icon';
import { useCapabilities } from 'src/hooks/use-capabilities';
import { hasFeatureFlag } from 'src/libs/feature-flags';
import { paths } from 'src/paths';
import { icons } from 'src/theme/icons';

export interface Item {
  disabled?: boolean;
  external?: boolean;
  icon?: ReactNode;
  items?: Item[];
  label?: ReactNode;
  path?: string;
  title: string;
  exact?: boolean;
  badge?: boolean;
  hidden?: boolean;
}

export interface Section {
  items: Item[];
  subheader?: string;
}

export const useSections = () => {
  const [canEditor, canManagement, canSettings] = useCapabilities(
    'editor',
    'management',
    'settings'
  );

  const chatNotifications = useGetUnreadQuery();

  return useMemo(() => {
    const userSections: Section[] = [
      {
        items: [
          {
            title: 'Aktuelles',
            path: paths.index,
            icon: <InlineIcon icon="fa6-solid:newspaper" />,
            exact: true
          },
          {
            title: 'Kalender',
            path: paths.calendar,
            icon: <InlineIcon icon="fa6-solid:calendar-days" />
          },
          {
            title: 'Dokumente',
            path: paths.documents,
            icon: <InlineIcon icon="fa6-solid:folder" />
          },
          {
            title: 'Chat',
            path: paths.chat,
            icon: <InlineIcon icon="fa6-solid:comment-dots" />,
            badge: !!chatNotifications.data?.total_unread_count,
            hidden: !hasFeatureFlag('chat')
          },
          {
            title: 'Wiki',
            path: paths.wiki,
            icon: <InlineIcon icon="fa6-solid:book" />,
            hidden: !hasFeatureFlag('wiki')
          }
        ].filter((section) => section !== undefined)
      }
    ];
    const contentSections: Section[] = [
      {
        subheader: 'Inhalte',
        items: [
          {
            title: 'Neuer Inhalt',
            path: paths.content.create,
            icon: <InlineIcon icon={icons.add} />,
            hidden: !canEditor
          },
          {
            title: 'Entwürfe',
            path: paths.content.drafts,
            icon: <InlineIcon icon="fa6-solid:file-lines" />,
            hidden: !canEditor
          },
          {
            title: 'Geplant',
            path: paths.content.planned,
            icon: <InlineIcon icon="fa6-solid:clock" />,
            hidden: !canEditor
          }
        ]
      }
    ];
    const adminSections: Section[] = [
      {
        subheader: 'Administration',
        items: [
          {
            title: 'Verwaltung',
            path: paths.admin.manage.index,
            icon: <InlineIcon icon="fa6-solid:users" />,
            hidden: !canManagement
          },
          {
            title: 'Einstellungen',
            path: paths.admin.settings.index,
            icon: <InlineIcon icon="fa6-solid:gear" />,
            hidden: !canSettings
          }
        ].filter((section) => section !== undefined)
      }
    ];
    const accountSections: Section[] = [
      {
        subheader: 'Einstellungen',
        items: [
          {
            title: 'Mein Betrieb',
            path: paths.company.user,
            icon: <InlineIcon icon="fa6-solid:building" />
          },
          {
            title: 'Mein Profil',
            path: paths.account,
            icon: <InlineIcon icon={icons.settings} />
          }
        ]
      }
    ];

    return (
      [
        ...userSections,
        ...contentSections,
        ...adminSections,
        ...accountSections
      ]
        // remove hidden items
        .map((section) => ({
          ...section,
          items: section.items.filter((item) => !item.hidden)
        }))
        // remove empty sections
        .filter((section) => section.items.length > 0)
    );
  }, [
    canEditor,
    canManagement,
    canSettings,
    chatNotifications.data?.total_unread_count
  ]);
};
