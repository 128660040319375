interface UserLike {
  firstName?: string;
  lastName?: string;
  companyAccount?: boolean;
  companyName?: string;
  company?: { name?: string };
}

export function getFullName(user: UserLike): string;
export function getFullName(
  user: UserLike | undefined,
  withFallback?: boolean
): string | undefined;
export function getFullName(
  user: UserLike | undefined,
  withFallback = true
): string | undefined {
  if (!user) return undefined;

  const { firstName, lastName, companyAccount, companyName, company } = user;
  const fallback = withFallback ? 'Unbekannt' : undefined;

  if (companyAccount) return companyName || company?.name || fallback;

  return firstName?.length && lastName?.length
    ? `${firstName} ${lastName}`
    : lastName?.length
    ? lastName
    : firstName?.length
    ? firstName
    : fallback;
}
