import { HttpResponse } from '@capacitor/core';
import {
  GetApiEventsByIdAttendeesExcelApiArg,
  deinVerbandApi
} from './deinverband-api';

export interface EnhancedCreateResponse {
  id: number;
  location: string;
}

const transformCreateResponse = (
  response: unknown,
  meta: HttpResponse | undefined,
  arg: unknown
): EnhancedCreateResponse => {
  const location = meta?.headers.location;

  if (location === undefined || location === null)
    throw new Error('Unexpected response on create');

  const path = location.split('/');
  return {
    id: parseInt(path[path.length - 1]),
    location
  };
};

export const enhancedApi = deinVerbandApi
  .injectEndpoints({
    endpoints: (build) => ({
      putCloudStorage: build.mutation<void, { url: string; file: File }>({
        queryFn: async ({ url, file }) =>
          fetch(url, {
            method: 'PUT',
            body: file,
            headers: { 'content-type': 'application/octet-stream' }
          })
            .then(() => ({ data: undefined }))
            .catch((error) => ({ error })),
        invalidatesTags: ['Document Resource']
      })
    })
  })
  .enhanceEndpoints({
    endpoints: {
      // invalidate resources that provide user membership
      postApiUsers: {
        transformResponse: transformCreateResponse,
        invalidatesTags: ['User Resource', 'Company Resource', 'Guild Resource']
      },
      patchApiUsersById: {
        invalidatesTags: ['User Resource', 'Company Resource', 'Guild Resource']
      },
      deleteApiUsersById: {
        invalidatesTags: ['User Resource', 'Company Resource', 'Guild Resource']
      },

      // invalidate resources that provide company membership
      postApiCompanies: {
        transformResponse: transformCreateResponse,
        invalidatesTags: ['Company Resource', 'Guild Resource']
      },
      patchApiCompaniesById: {
        invalidatesTags: ['Company Resource', 'Guild Resource']
      },
      deleteApiCompaniesById: {
        invalidatesTags: ['Company Resource', 'Guild Resource']
      },

      // invalidate resources that provide attendees
      postApiEventsByIdAttendees: {
        invalidatesTags: ['Event Resource', 'Post Resource']
      },

      // invalidate resources that provide responses
      patchApiSurveysById: {
        invalidatesTags: ['Post Resource', 'Survey Resource']
      },

      // extract identifiers of created resources
      postApiGuild: { transformResponse: transformCreateResponse },
      postApiLists: { transformResponse: transformCreateResponse },
      postApiPosts: { transformResponse: transformCreateResponse },
      postApiSurveys: { transformResponse: transformCreateResponse },
      postApiPostsByIdPublish: { transformResponse: transformCreateResponse },

      // custom response type
      getApiChatToken: { extraOptions: { responseType: 'text' } },

      // disable caching
      getApiEventsByIdAttendeesExcel: {
        query: (args: GetApiEventsByIdAttendeesExcelApiArg) => ({
          url: `/api/events/${args.id}/attendees_excel`,
          method: 'GET',
          params: args.status,
          cache: 'no-cache'
        }),
        extraOptions: { responseType: 'blob' }
      }
    }
  });
