import React, { FC } from 'react';
import { Icon } from '@iconify/react';
import { Button, ButtonProps } from '@mui/material';
import { styled } from '@mui/material/styles';

type IconifyButtonProps = {
  startIcon?: string;
  endIcon?: string;
  largeIcon?: boolean;
} & Omit<ButtonProps, 'startIcon' | 'endIcon'>;

const StyledButton = styled(Button)(({ theme }) => ({
  '.MuiButton-startIcon': {
    marginTop: '-0.25em'
  },
  '.MuiButton-endIcon': {
    marginTop: '-0.25em'
  }
}));

export const IconifyButton: FC<IconifyButtonProps> = ({
  startIcon,
  endIcon,
  largeIcon,
  ...buttonProps
}) => (
  <StyledButton
    {...buttonProps}
    startIcon={
      startIcon && (
        <Icon
          style={{
            fontSize: largeIcon ? 'normal' : 'small',
            marginRight: largeIcon ? '0.25em' : '0.1em'
          }}
          icon={startIcon}
        />
      )
    }
    endIcon={
      endIcon && (
        <Icon
          style={{
            fontSize: largeIcon ? 'normal' : 'small',
            marginRight: largeIcon ? '0.25em' : '0.1em'
          }}
          icon={endIcon}
        />
      )
    }
  />
);
