import { useCallback, useEffect, useMemo, useState } from 'react';
import { hasFeatureFlag } from 'src/libs/feature-flags';
import { Signature } from 'src/types/email';
import { Attendance } from 'src/types/occasion';
import {
  TargetingEditorState,
  TargetingEditorStateAttendees,
  TargetingEditorStateFilters,
  TargetingList,
  TargetingListFilter,
  TargetingListFilterCategories,
  TargetingListFilterRights,
  TargetingListFilterRoles,
  TargetingListFiltersGroups,
  TargetingListMember,
  TargetingListMemberRole
} from 'src/types/targeting';
import { MemberOrId } from 'src/types/user';

export const targetingEditorModes = ['list', 'filters', 'attendees'] as const;

export type TargetingEditorMode = (typeof targetingEditorModes)[number];

export const defaultRightsFilter: TargetingListFilterRights = {
  type: 'rights',
  memberships: [],
  groups: [],
  roles: []
};

export const defaultRolesFilter: TargetingListFilterRoles = {
  type: 'roles',
  roles: [],
  groups: []
};

export const defaultCategoriesFilter: TargetingListFilterCategories = {
  type: 'categories',
  memberships: [],
  groups: [],
  categories: []
};

export const defaultGroupsFilter: TargetingListFiltersGroups = {
  type: 'groups',
  memberships: [],
  groups: []
};

export const defaultFilters: TargetingEditorStateFilters = {
  method: 'filters',
  filters: [defaultRolesFilter],
  locations: null,
  members: null
};

export const defaultFilter = (type: string): TargetingListFilter => {
  if (type === 'rights') return defaultRightsFilter;
  if (type === 'roles') return defaultRolesFilter;
  if (type === 'categories') return defaultCategoriesFilter;
  if (type === 'groups') return defaultGroupsFilter;

  throw Error(`Unknown filter type: ${type}`);
};

export const validateRightsFilter = (filter: TargetingListFilter) =>
  filter.type === 'rights' &&
  (!hasFeatureFlag('memberships-filter') || !!filter.memberships.length) &&
  !!filter.groups.length &&
  !!filter.roles.length;

export const validateRolesFilter = (filter: TargetingListFilter) =>
  filter.type === 'roles' && !!filter.roles.length && !!filter.groups.length;

export const validateCategoriesFilter = (filter: TargetingListFilter) =>
  filter.type === 'categories' &&
  !!filter.memberships.length &&
  !!filter.groups.length &&
  !!filter.categories.length;

export const validateGroupsFilter = (filter: TargetingListFilter) =>
  filter.type === 'groups' &&
  !!filter.memberships.length &&
  !!filter.groups.length;

export const validateEditorStateFilters = (
  state: TargetingEditorStateFilters
) =>
  state.filters.some(
    (filter) =>
      validateRightsFilter(filter) ||
      validateRolesFilter(filter) ||
      validateCategoriesFilter(filter) ||
      validateGroupsFilter(filter)
  ) || !!state.members?.length;

export const validateEditorStateAttendees = (
  state: TargetingEditorStateAttendees
) => !!state.attendances.length;

export interface TargetingEditorListContext {
  handleListSelect: (list?: TargetingList) => void;
}

export interface TargetingEditorFiltersContext {
  handleFilterAdd: () => void;
  handleFilterChange: (index: number, type: string) => void;
  handleFilterPatch: (
    index: number,
    patch: Partial<TargetingListFilter>
  ) => void;
  handleFilterDelete: (index: number) => void;
  handleLocationsChange: (locations: string[] | null) => void;
  handleMembersChange: (members: TargetingListMember[] | null) => void;
  handleMembersPatch: (
    role: TargetingListMemberRole,
    members: MemberOrId[]
  ) => void;
  handleSignatureChange: (signature?: Signature) => void;
}

export interface TargetingEditorAttendancesContext {
  handleAttendancesChange: (attendances: Attendance[]) => void;
}

export interface TargetingEditorBaseContext {
  state: TargetingEditorState;
  valid: boolean;
  dirty: boolean;

  handleModeChange: (value: TargetingEditorMode) => void;
  handleChange: (state: TargetingEditorState) => void;
  handleReset: () => void;
}

export type TargetingEditorContext = TargetingEditorBaseContext &
  TargetingEditorListContext &
  TargetingEditorFiltersContext &
  TargetingEditorAttendancesContext;

export const useTargetingEditor = ({
  initial,
  reloadOnChange
}: {
  initial: TargetingEditorState;
  reloadOnChange?: boolean;
}): TargetingEditorContext => {
  const [state, setState] = useState(initial);
  const [dirty, setDirty] = useState(false);

  useEffect(
    () => {
      if (reloadOnChange) {
        setState(initial);
        setDirty(false);
      }
    },
    // reload only when initial values change
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [initial]
  );

  const valid = useMemo(
    () =>
      (state?.method === 'filters' && validateEditorStateFilters(state)) ||
      (state?.method === 'attendees' && validateEditorStateAttendees(state)),
    [state]
  );

  const changeState = useCallback((state: TargetingEditorState) => {
    setState(state);
    setDirty(true);
  }, []);

  const handleReset = useCallback(() => {
    setState(initial);
    setDirty(false);
  }, [initial]);

  const handleModeChange = useCallback(
    (value: TargetingEditorMode): void => {
      if (value === 'list') changeState(undefined);
      if (value === 'filters') changeState(defaultFilters);
    },
    [changeState]
  );

  const handleListSelect = useCallback(
    (list?: TargetingList): void => changeState(list?.state),
    [changeState]
  );

  const handleSignatureChange = useCallback(
    (signature?: Signature): void => {
      if (state?.method === 'filters')
        changeState({ ...state, signature: signature });
    },
    [changeState, state]
  );

  const handleFilterAdd = useCallback(() => {
    if (state?.method === 'filters')
      changeState({
        ...state,
        filters: [...state.filters, defaultRolesFilter]
      });
  }, [changeState, state]);

  const handleFilterChange = useCallback(
    (index: number, type: string) => {
      if (state?.method === 'filters')
        changeState({
          ...state,
          filters: state.filters.map((filter, i) =>
            i === index ? defaultFilter(type) : filter
          )
        });
    },
    [changeState, state]
  );

  const handleFilterPatch = useCallback(
    (index: number, patch: Partial<TargetingListFilter>) => {
      if (state?.method === 'filters')
        changeState({
          ...state,
          filters: state.filters.map((filter, i) =>
            i === index
              ? ({ ...filter, ...patch } as TargetingListFilter)
              : filter
          )
        });
    },
    [changeState, state]
  );

  const handleFilterDelete = useCallback(
    (index: number) => {
      if (state?.method === 'filters' && state.filters.length > 1)
        changeState({
          ...state,
          filters: state.filters.filter((_, i) => i !== index)
        });
    },
    [changeState, state]
  );

  const handleLocationsChange = useCallback(
    (locations: string[] | null) => {
      if (state?.method === 'filters')
        changeState({
          ...state,
          locations
        });
    },
    [changeState, state]
  );

  const handleMembersChange = useCallback(
    (members: TargetingListMember[] | null) => {
      if (state?.method === 'filters')
        changeState({
          ...state,
          members
        });
    },
    [changeState, state]
  );

  const handleMembersPatch = useCallback(
    (role: TargetingListMemberRole, members: MemberOrId[]) => {
      if (state?.method === 'filters' && state.members)
        changeState({
          ...state,
          members: [
            ...state.members?.filter((member) => member.role !== role),
            ...members.map((member) => ({ member, role }))
          ]
        });
    },
    [changeState, state]
  );

  const handleAttendancesChange = useCallback(
    (attendances: Attendance[]): void => {
      if (state?.method === 'attendees')
        changeState({
          ...state,
          attendances
        });
    },
    [changeState, state]
  );

  return {
    state,
    valid,
    dirty,
    handleReset,
    handleModeChange,
    handleChange: setState,
    handleListSelect,
    handleSignatureChange,
    handleFilterAdd,
    handleFilterChange,
    handleFilterPatch,
    handleFilterDelete,
    handleLocationsChange,
    handleMembersChange,
    handleMembersPatch,
    handleAttendancesChange
  };
};
